<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-alert
      v-if="alert.visible"
      color="primary"
      dark
      icon="fa-regular fa-hexagon-exclamation"
      border="left"
      prominent
    >
      {{ alert.description }}
      <v-btn
        v-if="alert.button.visible"
        color="secondary"
        elevation="2"
        @click="alert.button.cl"
      >
        <i v-if="alert.button.icon" :class="alert.button.icon"></i>
        {{ alert.button.text }}
      </v-btn>
    </v-alert>

    <view-intro class="text-start" heading="Alle gebruikers"/>

    <div v-if="loading" class="d-flex justify-content-center" style="margin-top: 30vh;">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <material-card
      v-else
      class="mt-6 elevation-1 mt-16"
      icon="fa-regular fa-users"
      icon-small
      title="Gebruikers"
      color="accent"
    >
      <v-data-table
        loading-text="Laden even geduld aub"
        :headers="headers"
        @click:row="goToUser"
        :items="users"
        :items-per-page="-1"
      ></v-data-table>
    </material-card>
  </v-container>
</template>

<script>
  export default {
    name: 'Gebruikers',
    data () {

      this.$auth.getStoreUsers().then(async users => {
        const usersArray = Object.values(users)
        const filteredUsers = this.$filter.filterStoreUsers(usersArray)
        const populatedUsers = []
        filteredUsers.forEach((user, userIndex) => {
          user.openOrdersCount = 0
          user.ordersRefs.forEach(async (order, index) => {
            const populatedOrder = await this.$auth.getOrderGroup(order.groupId)
            if (!user.orders) {
              user.orders = []
            }
            if (populatedOrder) {
              user.orders.push(populatedOrder)
              if (populatedOrder.status === 'open') {
                if (user.openOrdersCount === 0 || user.openOrdersCount) {
                  user.openOrdersCount += 1
                }
              }
            }
            if (index + 1 === user.ordersRefs.length) {
              populatedUsers.push(user)
            }
            return populatedOrder
          })
          if (userIndex + 1 === filteredUsers.length) {
            const interval = setInterval(() => {
              if (filteredUsers.length && (user.openOrdersCount === 0 || user.openOrdersCount)) {
                this.users = filteredUsers
                this.loading = false
                clearInterval(interval)
              }
            }, 10)
          }
        })
      }).catch(err => {
        this.alert.visible = true
        this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
      })
      return {
        loading: true,
        headers: [
          {
            text: 'Naam',
            value: 'name',
          },
          {
            text: 'Email',
            value: 'email',
          },
          {
            text: 'telefoon nummer',
            value: 'phoneNumber',
          },
          {
            text: 'Open bestellingen',
            value: 'openOrdersCount',
          },
        ],
        users: [],
        alert: {
          visible: false,
          description: '',
          button: {
            visible: false,
            text: null,
            cl: null,
          },
        },
      }
    },
    methods: {
      goToUser (user) {
        this.$router.push(`/users/${user.uid}`)
      },
    },
  }
</script>
